<template>
  <div class="table-box" id="tableResult">
    <div class="mt-5" v-if="loading">
        <loading-ajax key="tableSearchResult" v-if="loading"/>
    </div>
    <div class="text-center my-5" v-if="!hasResults && !loading && !hasApiErrors">
            Nenhum resultado encontrado
    </div>
    <div class="text-center my-5" v-if="hasApiErrors && !loading">
            Não foi possível fazer a busca. tente novamente mais tarde
    </div>

    <div v-if="hasResults">
        <b-table sticky-header="700px"
            :items="items"
            :fields="fields"
            :tbody-tr-class="setTbodyTrClass"
            :striped="striped"
            :small="small"
            :hover="hover"
        >
            <!-- Coluna Ficha Atual -->
            <template #cell(id_ficha)="row">

                <div class="alinhamento-vertical" v-if="!row.item.st_excluida">
                    <template v-if="row.item.id_ficha_publicada != null">
                        <button class="cl" @click="dowloadFicha(row.item.id_ficha)"  v-if="row.item.id_ficha_publicada != null">
                        <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt="Baixar ficha atual" title="Baixar ficha atual">
                        </button>
                        <button class="cl" @click="openCL(row.item.id_ficha)" v-if="row.item.id_ficha_publicada != null && !row.item.st_excluida">
                            <img class="icon-olho" :src="imageOlho" @mouseover="imageOHover" @mouseout="imageOlhoOut" alt="Visualizar dados" title="Visualizar dados">
                        </button>
                    </template>
                    <template v-else>
                        <img class="icon-olho" src="/img/icon-preparando.png" alt="Em preparação" title="Em preparação" style="width: 35px; opacity: .4;">
                    </template>

                </div>

                <!--
                <button class="cl" v-if="row.item.id_ficha_publicada == null" style="cursor:default;">
                    <img src="/img/pdf-disabled.png" alt="">
                </button>
                <button class="cl" @click="dowloadFicha(row.item.id_ficha)"  v-if="row.item.id_ficha_publicada != null">
                  <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt="Download da ficha" title="Download da ficha">
                </button>
                <button class="cl" v-if="row.item.id_ficha_publicada == null || row.item.st_excluida" style="cursor:default;">
                    <img class="icon-olho" style="cursor:pointer" src="/img/CL_Button-disabled.png" alt="" title="Ficha não publicada ou excluída">
                </button>
                <button class="cl" @click="openCL(row.item.id_ficha)" v-if="row.item.id_ficha_publicada != null && !row.item.st_excluida">
                    <img class="icon-olho" :src="imageOlho" @mouseover="imageOHover" @mouseout="imageOlhoOut" alt="Visualizar dados" title="Visualizar dados">
                </button>
                -->

            </template>

            <!-- Coluna Ficha Antiga -->
            <template #cell(sq_ficha_versao_antiga)="row">
                <div class="alinhamento-vertical" v-if="!row.item.st_excluida">
                    <template v-if="row.item.id_ficha_versao_antiga != null">
                        <button class="cl" @click="dowloadFichaVersao(row.item.id_ficha_versao_antiga)"  >
                        <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt="Baixar ficha antiga" title="Baixar ficha antiga" style="opacity: 0.7;">
                        </button>
                        <!--
                        <button class="cl" @click="openCL(row.item.id_ficha)" >
                            <img class="icon-olho" :src="imageOlho" @mouseover="imageOHover" @mouseout="imageOlhoOut" alt="Visualizar dados da ficha antiga" title="Visualizar dados da ficha antiga" style="opacity: 0.7;">
                        </button>
                        -->
                    </template>
                </div>

                <!--
                <button class="cl" v-if="row.item.id_ficha_publicada == null" style="cursor:default;">
                    <img src="/img/pdf-disabled.png" alt="">
                </button>
                <button class="cl" @click="dowloadFicha(row.item.id_ficha)"  v-if="row.item.id_ficha_publicada != null">
                  <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt="Download da ficha antiga" title="Download da ficha antiga" style="opacity: 0.5;">
                </button>
                <button class="cl" v-if="row.item.id_ficha_publicada == null || row.item.st_excluida" style="cursor:default;">
                    <img class="icon-olho" style="cursor:pointer" src="/img/CL_Button-disabled.png" alt="" title="Ficha não publicada ou excluída">
                </button>
                <button class="cl" @click="openCL(row.item.id_ficha)" v-if="row.item.id_ficha_publicada != null && !row.item.st_excluida">
                    <img class="icon-olho" :src="imageOlho" @mouseover="imageOHover" @mouseout="imageOlhoOut" alt="Visualizar dados" title="Visualizar dados" style="opacity: 0.5;">
                </button>
                -->
            </template>


            <template #cell(index)="data">
                {{ countRows(data.index) }}
            </template>
            <template #cell(checkbox)="row">
                <div class="table-checkbox">
                    <input type="checkbox" v-model="selected" :value="row.item.id_ficha">
                </div>
            </template>
            <template #cell(nm_cientifico)="row">
                    <div v-html="row.item.nm_cientifico" v-if="row.item.id_ficha_publicada == null"></div>
                    <div class="mousePointer" title="Clique para visualizar" v-html="row.item.nm_cientifico" @click="openCL(row.item.id_ficha)" v-else></div>

                    <i v-if="row.item.st_excluida" :title="'Clique para saber mais...'" @click="openModalInfo(row.item.nm_cientifico, row.item.ds_justificativa_exclusao)" class="st_excluida">&#8505; Excluída</i>

            </template>
<!--
            <template #cell(nm_cientifico_anterior)="row">
              <div v-html="row.item.nm_cientifico_anterior"></div>
            </template>
-->
            <template #cell(dt_fim_avaliacao)="row">
                    <div>{{formatDate(row.item.dt_fim_avaliacao)}}
                      <!--<br>{{row.item.ds_ajustada_em}} Issue #88-->
                    </div>
            </template>

            <template #cell(de_categoria_final_completa)="row">
                    <div>
                        <span v-if="row.item.st_excluida">
                            <!--
                            Exibe nada: https://gitlab.com/sisicmbio/app_salve/-/issues/250
                            Excluída
                            <i :title="'Clique para saber mais...'" @click="openModalInfo(row.item.nm_cientifico, row.item.ds_justificativa_exclusao)" class="cursorPointer fa fa-info-circle"></i>
                            -->
                        </span>
                        <span v-else>
                            {{row.item.de_categoria_final_completa}}
                            <i title="Em processo de avaliação" class="cursorPointer fa fa-info-circle" v-if="row.item.cd_categoria_final=='NE'"></i>
                        </span>

                    </div>
            </template>

            <template v-slot:head(checkbox)>
                <div class="table-checkbox">
                    <input type="checkbox" v-model="selectAll">
                </div>
            </template>

            <template v-slot:head(nm_cientifico)="row">
                <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                    <span>{{ row.label }}</span>
                    <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                    <i v-else class="fas fa-minus-circle"></i>
                </div>
            </template>
          <!--
                      <template v-slot:head(nm_cientifico_anterior)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>
          -->
                      <template v-slot:head(no_comum)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>
                      <template v-slot:head(ds_grupo_salve)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>
                      <template v-slot:head(de_categoria_final_completa)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder('cd_categoria_final')">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == 'cd_categoria_final')" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>
                      <template v-slot:head(dt_fim_avaliacao)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>
                      <template v-slot:head(sg_estado)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>
                      <template v-slot:head(no_bioma)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder(row.column)">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == row.column)" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>

                      <!-- Cabeçalho Ficha Atual -->
                      <template v-slot:head(id_ficha)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder('sq_ficha_publicada')">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == 'sq_ficha_publicada')" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>

                      <!-- Cabeçalho Ficha Antiga -->
                      <template v-slot:head(sq_ficha_versao_antiga)="row">
                          <div class="d-flex justify-content-between mousePointer" @click="activeOrder('sq_ficha_versao_antiga')">
                              <span>{{ row.label }}</span>
                              <i v-if="(order == 'sq_ficha_versao_antiga')" class="fa" :class="(orderDirection == 'asc') ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'"></i>
                              <i v-else class="fas fa-minus-circle"></i>
                          </div>
                      </template>

                  </b-table>
                  <Pagination v-if="totalPages != 0" :totalPages='totalPages'/>
              </div>
            </div>
          </template>

          <script>
          import api from '@/services/api.js'
          import Pagination from '@/components/globalComponents/Pagination.vue'
          import barramento from '@/barramento'

            export default {
              props: ['endpoint'],
              components: {
                  Pagination
              },
              data() {
                  return {
                      loading: true,
                      image: '/img/pdf.png',
                      clImageHover: '/img/pdf-hover.png',
                      imageOlho: '/img/CL_Button.png',
                      imageOlhoHover: '/img/CL_ButtonHover.png',
                      small:"small",
                      hover:"hover",
                      selected: [],
                      fields: [
                          {
                              key: 'index',
                              label: '#',

                          },
                          {
                              key: 'checkbox',
                              label: '',
                              tdClass: 'activeBorder colorBackground'

                          },
                          {
                              key: 'nm_cientifico',
                              label: 'Espécie',

                          }/*,
                          {
                              key: 'nm_cientifico_anterior',
                              label: 'Nome ciclo anterior',
                              tdClass: 'activeBorder colorBackground'
                          }*/,

                          {
                              key: 'no_comum',
                              label: 'Nome comum',
                              tdClass: 'activeBorder colorBackground'
                          },
                          {
                              key: 'ds_grupo_salve',
                              label: 'Grupo',
                              tdClass: 'activeBorder colorBackground'
                          },
                          {
                              key: 'de_categoria_final_completa',
                              label: 'Categoria',
                          },
                          {
                              key: 'dt_fim_avaliacao',
                              label: 'Data da categoria',
                              tdClass: 'activeBorder colorBackground'
                          },
                          {
                              key: 'sg_estado',
                              label: 'UF(s)',

                          },
                          {
                              key: 'no_bioma',
                              label: 'Bioma(s)',
                              tdClass: 'activeBorder colorBackground'
                          }/*,
                          {
                              key: 'id_ficha',
                              label: 'Ação',
                          }*/
                          ,
                          {
                              key: 'id_ficha',
                              label: 'Ficha Atual',
                              tdClass: 'activeBorder colorBackground'

                          },
                          {
                              key: 'sq_ficha_versao_antiga',
                              label: 'Ficha Antiga',

                          }
                      ],
                      order: '',
                      orderDirection: 'asc',
                      apiRseult: {},
                      items: [],
                      total: 0,
                      totalFichasPublicadas:0,
                      totalPages: 0,
                      currentPage: 1,
                      hasResults:false,
                      hasApiErrors:false,
                      paginationFirstRowNum:0,
                      countRowsParam:0,
                      pageNumber:0,
                  }
              },
              mounted() {
                if (this.endpoint.indexOf(';') == -1) {
                  window.grecaptcha.ready(async () => {
                    let token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
                    this.onRecaptchaReady(token);
                  });
                }
                this.$on('changePage', page => {
                  this.newPage(page)
                })
              },
              watch: {
                  total() {
                      this.$emit('total', this.total)
                      this.$emit('totalFichasPublicadas', this.totalFichasPublicadas)
                      barramento.$emit('totalResult', this.total)
                  },
                  selected() {
                      this.$emit('tableSearchResultSelected', this.selected, this.query)
                      barramento.$emit('totalResultSelected', this.selected)
                  }
              },
              computed: {
                  selectAll: {
                      get: function () {
                          return this.items ? this.selected.length == this.items.length : false;
                      },
                      set: function (value) {
                          var selected = [];

                          if (value) {
                              this.items.forEach(function (item) {
                                  selected.push(item.id_ficha);
                              });
                          }

                          this.selected = selected;
                      }
                  }
              },

              methods: {
                async onRecaptchaReady(token){
                 if( ! token ){
                    return;
                  }
                  var t = this
                  //console.log(' ' )
                  //console.log('End point: ' + this.endpoint)
                  // eslint-disable-next-line
                  // debugger;
                  if( this.endpoint.indexOf(';') == -1 ) {
                    await api.get(this.endpoint+'&reCaptchaToken='+token).then((response) => {

                      if (response != undefined) {
                        t.hasApiErrors = false
                        t.apiRseult = response.data
                        if( response.data.msg ){
                          alert( response.data.msg );
                        }
                      } else {
                        t.hasApiErrors = true
                      }
                      if (t.apiRseult.data != null) {
                        t.hasResults = true
                      } else {
                        t.hasResults = false
                      }
                    }).finally(() => this.loading = false)

                    this.items = this.apiRseult.data

                    this.totalFichasPublicadas=0;

                    if (this.items) {
                      if (this.apiRseult.pagination.paginationTotalRecords) {
                        this.total = this.apiRseult.pagination.paginationTotalRecords
                        this.totalPages = this.apiRseult.pagination.paginationTotalPages
                        this.pageNumber = this.apiRseult.pagination.paginationPageNumber
                        this.paginationFirstRowNum = this.apiRseult.pagination.paginationFirstRowNum
                      } else {
                        this.total = this.items.length
                        this.pageNumber = 1
                        this.paginationFirstRowNum = this.apiRseult.pagination.paginationFirstRowNum
                        // this.countRows = 1
                      }
                      var totalFichasPublicadas = 0;
                      this.items.forEach( function (item) {
                        if( item.cd_situacao_ficha == 'PUBLICADA'){
                          totalFichasPublicadas++
                        }
                      });
                      this.totalFichasPublicadas = totalFichasPublicadas;
                    }
                  }
                  else
                  {
                    let urlParts = this.endpoint.split('?');
                    let endPoint = urlParts[0];
                    let query = urlParts[1]
                    let searchParams = {};
                    query.split("&").forEach(function (part) {
                      let item = part.split("=");
                      searchParams[item[0]] = decodeURIComponent(item[1]);
                    });
                    searchParams['reCaptchaToken']=token;
                    const ps = new URLSearchParams(searchParams);
                    const axiosConfig = {headers: {'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "*"}};
                    await api.post(endPoint, ps, axiosConfig).then((response) => {
                      if (response != undefined) {
                        t.hasApiErrors = false
                        t.apiRseult = response.data
                      } else {
                        t.hasApiErrors = true
                      }
                      if (t.apiRseult.data != null) {
                        t.hasResults = true
                      } else {
                        t.hasResults = false
                      }
                    }).finally(() => this.loading = false)
                    this.items = this.apiRseult.data
                    if (this.items) {
                      if (this.apiRseult.pagination.paginationTotalRecords) {
                        this.total = this.apiRseult.pagination.paginationTotalRecords
                        this.totalPages = this.apiRseult.pagination.paginationTotalPages
                        this.pageNumber = this.apiRseult.pagination.paginationPageNumber
                        this.paginationFirstRowNum = this.apiRseult.pagination.paginationFirstRowNum
                      } else {
                        this.total = this.items.length
                        this.pageNumber = 1
                        this.paginationFirstRowNum = this.apiRseult.pagination.paginationFirstRowNum
                        // this.countRows = 1
                      }
                    } else {
                      this.total = 0
                      this.totalFichasPublicadas = 0
                    }
                  }

                },
                openModalInfo(title, content){
                    barramento.$emit('modal_info',title, 'Motivo da exclusão:<br>' + content)
                  },

                  countRows(value) {
                      if (this.pageNumber >= 2) {
                          count = this.paginationFirstRowNum + value
                      } else {
                          var count = value + 1
                      }
                      return count
                  },
                  activeOrder(col){
                      if(this.order == col){
                          if (this.orderDirection == 'asc'){
                              this.orderDirection = 'desc'
                          } else {
                              this.orderDirection = 'asc'
                          }
                      } else {
                          this.order = col
                          this.orderDirection = 'asc'

                      }
                      this.newPage(this.currentPage)
                  },
                  dowloadFicha(id) {
                      // window.open('/salve-api/public/fichaPdf/' + id, "_blank")
                      window.open('/salve-api/public/fichaPdf/' + id, "_blank")
                  },
                  dowloadFichaVersao(id) {
                      window.open('/salve-api/public/fichaVersaoPdf/' + id, "_blank")
                  },
                  imageHover(e) {
                      e.target.src = this.clImageHover
                  },
                  imageOut(e) {
                      e.target.src = this.image
                  },
                  imageOHover(e) {
                      e.target.src = this.imageOlhoHover
                  },
                  imageOlhoOut(e) {
                      e.target.src = this.imageOlho
                  },
                  setTbodyTrClass(item) {
                      const classes = [];
                      if(item) classes.push('custom-border');
                      return classes;
                  },
                  openCL(url) {
                      barramento.$emit('openModalFicha', url)
                  },
                  formatDate(dateStr) {
                      if (dateStr !== null) {
                          var dateArr = dateStr.split("-");
                          var dayArr = dateArr[2].split("T");
                          return dayArr[0] + "/" + dateArr[1] + "/" + dateArr[0]
                      } else {
                          return ''
                      }
                  },
                  async newPage(page){
                      this.loading = true;
                      var newEndpoint = ''
                      this.currentPage = page
                      if(this.order != ''){
                          newEndpoint = this.endpoint + '&paginationPageNumber=' + page + '&sortColumn='+ this.order + '&sortOrder=' + this.orderDirection
                      } else {
                          newEndpoint = this.endpoint + '&paginationPageNumber=' + page
                      }
                      var t = this
                    window.grecaptcha.ready(async () => {
                      let token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
                      await api.get(newEndpoint+'&reCaptchaToken='+token).then(response => {
                          t.items = response.data.data
                          this.pageNumber = response.data.pagination.paginationPageNumber
                          this.paginationFirstRowNum = response.data.pagination.paginationFirstRowNum
                      }).finally(() => this.loading = false)
                    });
                  }
              }
            }
          </script>

<style scoped>
.cursorPointer{
  cursor:pointer;
}

.mousePointer {
    cursor:pointer;
    text-decoration: underline;
}
.icon-olho{
    width:25px;
}

.st_excluida{
    color: red;
    cursor: pointer;
    font-weight: 200;
}

.alinhamento-vertical{
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    margin: 50%;
}
</style>
